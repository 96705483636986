import _toStringTagSupport from "../internals/to-string-tag-support";
import _classof from "../internals/classof";
var exports = {};
var TO_STRING_TAG_SUPPORT = _toStringTagSupport;
var classof = _classof; // `Object.prototype.toString` method implementation
// https://tc39.es/ecma262/#sec-object.prototype.tostring

exports = TO_STRING_TAG_SUPPORT ? {}.toString : function toString() {
  return "[object " + classof(this) + "]";
};
export default exports;